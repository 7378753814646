import './App.css'
// Global imports
import PropTypes from 'prop-types'
import React, { lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/pt'
import { useSelector, useDispatch } from 'react-redux'
import { loader } from 'graphql.macro'

// Local imports
import ToastContainer from 'components/ToastContainer/ToastContainer'
import UpdateNotificationContainer from '../UpdateNotificationContainer/UpdateNotificationContainer'
import logError from '@utils/logError'
import { sessionDataRes } from 'store/actions/sessionDataActions'

import themes from 'styles/themes'
import materialUITheme from 'styles/material'
import styled, { ThemeProvider } from 'styled-components/macro'
import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles'

import GlobalStyles from 'styles/globalStyle'

import ImportRetry from 'components/ImportRetry'
import Loading from 'components/Loading/Loading'
import OnlyDesktop from 'components/OnlyDesktop/OnlyDesktop'
import withAuthRoute from '@wrappers/withAuthRoute'
import { useLazyQuery } from '@apollo/client'
import { useTranslationService } from '@contexts/translation-context'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@hooks/useRouter'
import { transformDateRange } from 'store/actions/globalFiltersActions'
import { transformFilterDateRange } from 'store/reducers/filters'
import Snackbar from 'components/Snackbar/Snackbar'
import useSnackbar from 'apollo/hooks/useSnackbar'
import { normalizeUser } from 'models/User'

// Modals
import ConnectIntegration from '@containers/Modals/ConnectIntegration/ConnectIntegration'
import DisconnectIntegration from '@containers/Modals/DisconnectIntegration/DisconnectIntegration'
import RemoveDialogModal from '@containers/Modals/RemoveDialogModal/RemoveDialogModal'
import ShareMention from '@containers/Modals/ShareMention/ShareMention'
import CreateReviewProductOptions from '@containers/Modals/CreateReviewProductOptions/CreateReviewProductOptions'

const LazyLoginView = lazy(() =>
  ImportRetry(() => import('@views/Auth/Login/Login')),
)

const LazyRecoverPassword = lazy(() =>
  ImportRetry(() => import('@views/Auth/RecoverPassword/RecoverPassword')),
)

const LazyResetPassword = lazy(() =>
  ImportRetry(() => import('@views/Auth/ResetPassword/ResetPassword')),
)

const LazyLogoutView = lazy(() =>
  ImportRetry(() => import('@views/Auth/Logout/Logout')),
)

const LazyNotFoundView = lazy(() =>
  ImportRetry(() => import('@views/NotFound/NotFound')),
)

// Components view with Auth
const LazyProductViewLoader = lazy(() =>
  ImportRetry(() => import('@views/Product/ProductView')),
)
const ProductViewLoaderRoute = withAuthRoute(LazyProductViewLoader)

const LazyDashboardView = lazy(() =>
  ImportRetry(() => import('@views/Dashboard/Dashboard')),
)
const DashboardViewRoute = withAuthRoute(LazyDashboardView)

const USER_DATA_QUERY = loader('../../queries/UserDataQuery.graphql')

const propTypes = {
  error: PropTypes.string,
  location: PropTypes.object.isRequired,
}

const defaultProps = {
  error: null,
}

const AppRoot = () => {
  const { t } = useTranslation('errors')
  const isLoggedIn = useSelector(state => state.sessionData.meta.isLoggedIn)
  const error = useSelector(state => state.sessionData.meta.error)
  const gfDateRange = useSelector(state => state.globalFilters.dateRange)
  const fDateRange = useSelector(state => state.filters.dateRange)
  const route = useRouter()
  const { snackbarProps } = useSnackbar()

  const dispatch = useDispatch()
  const [{ currentLanguage, changeLanguage }] = useTranslationService()

  const hasRedirect = new URLSearchParams(route.location.search).get('redirect')

  const [loadUserData, { called, loading, error: queryError }] = useLazyQuery(
    USER_DATA_QUERY,
    {
      variables: { withClient: true },
      fetchPolicy: 'cache-and-network',
      onCompleted: info => {
        if (!queryError && !loading && info) {
          const user = normalizeUser(info.user)
          const { language } = user
          const { client } = user
          const { brands } = client
          window.localStorage.setItem('userEmail', user.email)

          dispatch(sessionDataRes({ user, client, brands }))
          dispatch(transformDateRange(gfDateRange))
          dispatch(transformFilterDateRange(fDateRange))
          // capture globalFilters dateRange and check whether is a date or string

          // change app current language by user's default language from his profile
          if (language) {
            changeLanguage(language)
          }
          moment.locale(currentLanguage)
        }
      },
    },
  )

  React.useEffect(() => {
    // Update pt weekDays defaults: (1a,2a,3a,4a,5ta,Sa,Do)
    moment.updateLocale('pt', {
      weekdaysMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      weekdays: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
      ],
    })
  }, [])

  const callLoadUserData = React.useCallback(() => {
    if (isLoggedIn) {
      loadUserData()
    }
  }, [isLoggedIn, loadUserData])

  React.useEffect(() => {
    callLoadUserData()
  }, [callLoadUserData])

  if (called && loading) {
    return <Loading />
  }
  if (error) {
    logError(error)

    return (
      <div className="c-App__error">
        <h1>{t('errors:thereIsAnError', 'Ha ocurrido un error')}</h1>
        <p>
          {t('errors:tryAgainLater', 'Inténtalo más tarde o escríbenos')}{' '}
          a&nbsp;
          <a href="mailto:support@sentisis.com">support@sentisis.com</a>
        </p>
      </div>
    )
  }

  return (
    <MaterialUIProvider theme={materialUITheme}>
      <ThemeProvider theme={themes.light}>
        <OnlyDesktop>
          <GlobalStyles />
          <AppWrapper>
            <Switch>
              <DashboardViewRoute isLoggedIn={isLoggedIn} exact path="/" />
              <DashboardViewRoute
                isLoggedIn={isLoggedIn}
                path="/profile"
                component={LazyDashboardView}
              />
              <Route
                path="/login"
                render={props => {
                  return isLoggedIn && !hasRedirect ? (
                    <Redirect to="/" />
                  ) : (
                    <LazyLoginView {...props} />
                  )
                }}
              />
              <Route
                exact
                path="/password-recovery"
                component={LazyRecoverPassword}
              />
              <Route
                exact
                path="/reset-password"
                component={LazyResetPassword}
              />
              <Route exact path="/create-user" component={LazyResetPassword} />

              <Route path="/logout" component={LazyLogoutView} />

              <ProductViewLoaderRoute
                isLoggedIn={isLoggedIn}
                path="/:slug/:product"
              />
              <Route component={LazyNotFoundView} />
            </Switch>
            <ToastContainer />
            <UpdateNotificationContainer />
            <Snackbar {...snackbarProps} />
            <RemoveDialogModal />
            <ShareMention />
            <ConnectIntegration />
            <DisconnectIntegration />
            <CreateReviewProductOptions />
          </AppWrapper>
        </OnlyDesktop>
      </ThemeProvider>
    </MaterialUIProvider>
  )
}

AppRoot.propTypes = propTypes
AppRoot.defaultProps = defaultProps

export default React.memo(AppRoot)

// Styles
const AppWrapper = styled.div`
  height: 100%;
`
