import PropTypes from 'prop-types'
import { ProductType, UserRole } from 'apollo/generated/globalTypes'
import type { GetUserData_user } from 'apollo/generated/GetUserData'
import type { GetRecoverData_user } from 'apollo/generated/GetRecoverData'
import { Client, normalizeClient } from './Client'
import type { Brand } from './Brand'

export const USER_ADMIN_ROLES = [UserRole.CLIENT_ADMIN]
export const USER_MANAGEMENT_ROLES = [...USER_ADMIN_ROLES, UserRole.NORMAL]

export type User = {
  email: string
  id: string
  intercomHash?: string
  language?: string
  name: string
  onboarding?: Onboarding
  timezone: string
  client?: Client
  readOnly?: boolean
  brands?: [Brand]
  role: UserRole
}

export type Onboarding = {
  maps: boolean
  explore: boolean
  race: boolean
  drive?: boolean
}

// Shapes

const onboardingShape = PropTypes.shape({
  drive: PropTypes.bool,
  expore: PropTypes.bool,
  maps: PropTypes.bool,
  race: PropTypes.bool,
  __typename: PropTypes.string,
})

export const userShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onboarding: PropTypes.oneOfType([
    onboardingShape,
    PropTypes.objectOf(PropTypes.bool),
  ]).isRequired,
})

export const normalizeUser = (input: GetUserData_user) => {
  const userRole = input?.role || UserRole.READ_ONLY

  return {
    id: input?.id || '',
    name: input?.name || '',
    email: input?.email || '',
    timezone: input?.timezone || '',
    language: input?.language || '',
    role: userRole,
    readOnly: userRole === UserRole.READ_ONLY,
    intercomHash: input?.intercomHash || '',
    onboarding: input?.onboarding || {
      drive: false,
      race: false,
      explore: false,
      maps: false,
    },
    client: input?.client ? normalizeClient(input?.client) : null,
  }
}

export const normalizeRecoverData = (input: GetRecoverData_user) => ({
  client: input?.client
    ? {
        quota: {
          limit: input?.client?.quota?.limit || 0,
          reached: input?.client?.quota?.reached || 0,
        },
        recoversInfo: {
          hasPendingRecovers:
            input?.client?.recoversInfo?.hasPendingRecovers || false,
        },
      }
    : null,
  product: input?.product
    ? {
        id: input?.product?.id || '',
        type: input?.product?.type || ProductType.DRIVE,
        feeds: ('feeds' in input?.product && input?.product?.feeds) || [],
      }
    : null,
  brand: input?.brand
    ? {
        id: input?.brand?.id || '',
        name: input?.brand?.name || '',
        slug: input?.brand?.slug || '',
        headerLogoUrl: input?.brand?.headerLogoUrl || '',
        availableMonitors: input?.brand?.availableMonitors || [],
        networks: input?.brand?.networks || {},
      }
    : null,
})
