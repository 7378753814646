import { GetUserData_user_client } from 'apollo/generated/GetUserData'
import { Brand } from './Brand'
import { normalizeProject } from './Project'

export type Client = {
  id: string
  brands?: [Brand]
  logo_url?: string
  contact?: Contact
  name?: string
  country?: string
  countryCode?: string
  language: string
  addons?: [string]
  quota: Quota
  recoversInfo: RecoversInfo
}

export type Contact = {
  name?: string
  email?: string
}

export type Quota = {
  limit: number
  reached: number
}

export type RecoversInfo = {
  hasPendingRecovers: boolean
}

export const normalizeClient = (input: GetUserData_user_client) => ({
  id: input?.id || '',
  logo_url: input?.logo_url || '',
  contact: {
    name: input?.contact?.name || '',
    email: input?.contact?.email || '',
  },
  name: input?.name || '',
  country: input?.country || '',
  countryCode: input?.countryCode || '',
  addons: input?.addons || [],
  quota: {
    limit: input?.quota?.limit || 0,
    reached: input?.quota?.reached || 0,
    displayDataConsumption: input?.quota?.displayDataConsumption || false,
  },
  recoversInfo: {
    hasPendingRecovers: input?.recoversInfo?.hasPendingRecovers || false,
  },
  brands: input?.brands
    ? // @ts-ignore
      input?.brands.map(brand => normalizeProject(brand))
    : [],
})
