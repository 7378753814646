import Moment from 'moment'
import {
  concat,
  map,
  set,
  flow,
  getOr,
  defaultTo,
  isString,
  identity,
} from 'lodash/fp'
import { extendMoment } from 'moment-range'
import { PURGE } from 'redux-persist'
import { lensPath, over } from '@utils/lens'

import {
  REQ_USER_LIST,
  REQ_VIEWS_LIST,
  RESET_ALLOWED_SOURCES,
  RES_USER_LIST,
  RES_VIEWS_LIST,
  SET_ALLOWED_SOURCES,
  SET_DATE_RANGE,
  SET_DEFAULT_DATE,
  SET_SOURCE,
  SET_USER_FILTER,
  SET_VIEW,
  SET_DEFAULT_VIEW,
  ENABLE_REAL_TIME,
  DISABLE_REAL_TIME,
  TOGGLE_USER_FILTER,
  CLEAR_VIEWS,
  SET_PRODUCT,
  SET_FEED,
  FILTERS_REHYDRATE,
  TRANSFORM_DATE_RANGE,
  UPDATE_VIEWS_LIST,
  SET_IS_REVIEWS,
  RESET_FEED,
} from '../actions/globalFiltersActions'

import {
  SET_DATE_RANGE as FILTERS_SET_DATE_RANGE,
  SET_FILTER as FILTERS_SET_FILTER,
  OVERWRITE_FILTERS as FILTERS_OVERWRITE_FILTERS,
} from './filters'

import { decode, urlIsExploreTrends, urlIsCategories } from '@utils/urlFilters'
import { ProductType } from 'models/Product'

const moment = extendMoment(Moment)

/**
 * Update the filters versions
 */
const updateVersion = state => {
  const currentVersion = getOr(0, 'meta.version', state)
  return set('meta.version', currentVersion + 1, state)
}

/**
 * Global Filter reducer
 */

// Try to get the initial source and dateRange from the URL directly.
// Catch and ignore any error as this it simply an optimisation
const initSource = 'all'
const initDateRange = moment.range(
  moment().subtract(6, 'days').startOf('day'),
  moment().endOf('day'),
)

const initialState = {
  user: null,
  view: undefined,
  source: initSource,
  dateRange: initDateRange,
  product: undefined,
  feed: undefined,
  allowedSources: [
    'all',
    'twitter',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'media',
    'review',
    'linkedin',
  ],

  // realTimeInterval is set to the time interval to fetch the mentions for
  // expressed in minutes. When disable, it's set to 0
  realTimeInterval: 0,

  meta: {
    version: 1,

    // Used when the filters show the user selector
    isFetchingUsers: false,
    showUserFilter: false,
    users: [],

    // Holds the array of available views, simplified to be just id, slug, name
    isFetchingViews: false,
    views: [],
    // Holds the id of the latest product we fetched the view for
    lastFetchProductId: '',
  },
}

export default function globalFilters(state = initialState, action) {
  const { type, payload, meta } = action

  switch (type) {
    case TRANSFORM_DATE_RANGE: {
      return flow(
        set(
          'dateRange',
          moment.range(payload.dateRange.start, payload.dateRange.end),
        ),
      )(state)
    }

    case FILTERS_SET_DATE_RANGE:
    case SET_DATE_RANGE: {
      // Only update when the new date range is different
      if (
        state.dateRange.start.isSame(payload.dateRange.start) &&
        state.dateRange.end.isSame(payload.dateRange.end)
      ) {
        return state
      }

      return flow(set('dateRange', payload.dateRange), updateVersion)(state)
    }

    case SET_DEFAULT_DATE: {
      // Only update when the new date range is different
      // TODO: aquí se está seteando el rango de fechas del filtro del dateRangePicker cuando las
      // fechas del estado con respecto a la initRangeDate difieran. Pero es esto lo que queremos?
      if (
        state.dateRange.start.isSame(initDateRange.start) &&
        state.dateRange.end.isSame(initialState.end)
      )
        return state

      return flow(set('dateRange', initDateRange), updateVersion)(state)
    }

    case ENABLE_REAL_TIME:
      return set('realTimeInterval', payload.interval, state)

    case DISABLE_REAL_TIME:
      return set('realTimeInterval', 0, state)

    case SET_SOURCE: {
      // Only update when the source is different
      if (state.source === payload.source) return state

      return flow(set('source', payload.source), updateVersion)(state)
    }

    case FILTERS_REHYDRATE: {
      if (payload && !!payload['filters']) {
        const { filters, globalFilters } = payload
        const safeQueryString = flow(
          defaultTo(undefined, url => isString(url) && url.split('q=')[1]),
          // Remove search param from query string, causes bad decode
          defaultTo(identity, q => isString(q) && q.split('?')[0]),
        )(window.location.href)

        const urlFilters = decode(safeQueryString)

        // if persisted state differs with the URL filters, overwrite with urlQueryString
        if (safeQueryString !== filters.queryString && urlFilters.source) {
          return { ...globalFilters }
        }
      }

      return state
    }

    case SET_ALLOWED_SOURCES: {
      const { sources } = payload
      const source = sources.includes(state.source) ? state.source : sources[0]

      return flow(
        set('allowedSources', [...sources]),
        set('source', source),
      )(state)
    }

    case RESET_ALLOWED_SOURCES: {
      return set('allowedSources', [...initialState.allowedSources], state)
    }

    case RESET_FEED: {
      return set('feed', initialState.feed, state)
    }

    case SET_USER_FILTER:
      return flow(set('user', payload.user), updateVersion)(state)

    case TOGGLE_USER_FILTER:
      return set('meta.showUserFilter', !state.meta.showUserFilter, state)

    case REQ_USER_LIST:
      return set('meta.isFetchingUsers', true, state)

    case RES_USER_LIST:
      return flow(
        set('meta.users', payload.users),
        set('meta.isFetchingUsers', false),
      )(state)

    case SET_VIEW: {
      if (payload.view === state.view) return state

      return flow(set('view', payload.view), updateVersion)(state)
    }

    case SET_DEFAULT_VIEW: {
      // search for the view in views and set default prop to true
      // and force the remaining ones to be false to ensure that only one can be true
      const views = map(x =>
        x.id === payload.view
          ? set('default', true, x)
          : set('default', false, x),
      )(state.meta.views)
      return set('meta.views', views, state)
    }

    case REQ_VIEWS_LIST:
      return flow(
        set('meta.isFetchingViews', true),
        set('meta.views', []),
      )(state)

    case RES_VIEWS_LIST: {
      return flow(
        set('meta.views', payload.views),
        set('meta.lastFetchProductId', meta.productId),
        set('meta.isFetchingViews', false),
      )(state)
    }

    case CLEAR_VIEWS:
      return flow(
        set('meta.views', []),
        set('meta.lastFetchProductId', undefined),
        set('meta.isFetchingViews', false),
        set('view', undefined),
      )(state)

    case SET_FEED: {
      const url = window.location.pathname

      if (urlIsExploreTrends(url) || urlIsCategories(url)) {
        return set('feed', payload.feedId, state)
      }
      return state
    }

    case SET_PRODUCT:
      return set('product', payload.productId, state)

    case FILTERS_SET_FILTER: {
      const { key, value } = payload
      if (['view', 'source', 'product'].includes(key[0])) {
        const val = key[0] === 'source' ? value.toLowerCase() : value
        return set(key, val, state)
      }
      return state
    }

    case FILTERS_OVERWRITE_FILTERS: {
      const { filters } = payload

      const rest = flow(
        set(
          'dateRange',
          moment.range(
            moment(new Date(filters.from)),
            moment(new Date(filters.to)),
          ),
        ),
        set('product', filters.product || state.product),
        set(
          'source',
          (filters.source && filters.source.toLowerCase()) || state.source,
        ),
        set('view', filters.view || state.view),
      )(state)

      return rest
    }

    case PURGE: {
      return initialState
    }

    case UPDATE_VIEWS_LIST: {
      return over(lensPath('meta.views'), concat([payload]), state)
    }

    case SET_IS_REVIEWS: {
      const { product } = payload
      const exclusiveSources = getOr([], 'exclusiveSources', product)

      const isReviews =
        !!(
          exclusiveSources.length === 1 && exclusiveSources.includes('REVIEWS')
        ) ||
        [ProductType.REVIEWS, ProductType.REVIEWS_BENCHMARK].includes(
          product?.type,
        )

      return set('isReviews', isReviews, state)
    }

    default:
      return state
  }
}
